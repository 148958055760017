/*
HEXCLOCK
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Jun 7, 2022
UPDATE DATE: Jun 7, 2022
REVISION: 1
NAME: index.scss
TYPE: Cascading Style Sheet
DESCRIPTION: HexClock | Time in Color!
PALETTE: -
*/

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video,xmp{border:0;margin:0;padding:0;font-size:100%}html,body{height:100%}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}b,strong{font-weight:bold}img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}ol,ul{list-style:none}li{display:list-item}table{border-collapse:collapse;border-spacing:0}th,td,caption{font-weight:normal;vertical-align:top;text-align:left}q{quotes:none}q:before,q:after{content:'';content:none}sub,sup,small{font-size:75%}sub,sup{line-height:0;position:relative;vertical-align:baseline}sub{bottom:-0.25em}sup{top:-0.5em}svg{overflow:hidden}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Regular.woff2') format('woff2'),
       url('./assets/font/Exo-Regular.woff') format('woff'),
       url('./assets/font/Exo-Regular.ttf') format('ttf'),
       url('./assets/font/Exo-Regular.eot') format('eot'),
       url('./assets/font/Exo-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Bold.woff2') format('woff2'),
       url('./assets/font/Exo-Bold.woff') format('woff'),
       url('./assets/font/Exo-Bold.ttf') format('ttf'),
       url('./assets/font/Exo-Bold.eot') format('eot'),
       url('./assets/font/Exo-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Thin.woff2') format('woff2'),
       url('./assets/font/Exo-Thin.woff') format('woff'),
       url('./assets/font/Exo-Thin.ttf') format('ttf'),
       url('./assets/font/Exo-Thin.eot') format('eot'),
       url('./assets/font/Exo-Thin.svg') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

%anim{
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
}

html{
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body{
  background: #FFF;
  color: #999;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  margin: 0;
  font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

a{
  text-decoration: none;
  color: #666;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  &:hover{
    color: #333;
  }
}

button,
input{
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 12px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  @extend %anim;

  // &:hover{}

  &:focus{
    outline: none;
  }
}

button{
  cursor: pointer;
  
  *{
    pointer-events: none;
  }
}

.react-select{
  .react-select__control{
    border: 1px solid #CCC;
    border-radius: 5px;
    min-height: 40px;
    box-shadow: none;
    @extend %anim;

    &.react-select__control--is-focused,
    &:hover{
      border: 1px solid #999;

      .react-select__indicators{
        background: #999;
      }
    }

    .react-select__value-container{
      padding: 0 15px;

      .react-select__single-value{
        margin: 2px 0 0 0;
        font-size: 14px;
        line-height: 18px;
      }

      .react-select__input-container{
        margin: 0;
        padding: 0;

        input{
          font-size: 14px !important;
        }
      }
    }

    .react-select__indicators{
      cursor: pointer;
      background: #CCC;
      border-radius: 0 4px 4px 0;
      @extend %anim;

      .react-select__indicator-separator{
        display: none;
        width: 0;
      }

      .react-select__indicator{
        color: #FFF;
        padding: 10px;

        svg{
          width: 18px;
          height: 18px;
          @extend %anim;
        }
      }
    }

    &.react-select__control--menu-is-open{
      border-radius: 5px 5px 0 0;

      .react-select__indicators{
        border-radius: 0 4px 0 0;

        svg{
          transform: rotate(-180deg);
        }
      }
    }
  }
}

.react-select__menu-portal{
  z-index: 4 !important;

  .react-select__menu{
    margin: 0;
    border-radius: 0 0 5px 5px;
    border: 1px solid #999;
    border-top: none;
    box-shadow: 0 20px 20px -10px rgba(0,0,0,.1);
    overflow: hidden;

    .react-select__menu-list{
      padding: 0;

      .react-select__option{
        cursor: pointer;
        color: #666;
        padding: 10px 15px;
        font-size: 14px;
        line-height: 18px;
        @extend %anim;

        &.react-select__option--is-focused{
          background: #EEE;
        }

        &.react-select__option--is-selected{
          background: #666;
          color: #FFF;
        }
      }
    }
  }
}

.Toastify__toast-container{
  bottom: 20px !important;
  // right: 21px !important;
  padding: 0 !important;
  pointer-events: none;

  .Toastify__toast{
    font-family: 'Exo', Helvetica, Arial, sans-serif !important;
    font-size: 12px;
    line-height: 16px;
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, .1);
    margin-top: 10px;
    margin-bottom: 0;
    padding: 15px 0 20px 0 !important;
    border-radius: 5px;
    pointer-events: auto;
  
    .Toastify__close-button{
      width: 16px;
      margin: 0 10px 0 0;
    }

    .Toastify__progress-bar-theme--light{
      background: #999;
    }

    .Toastify__toast-body{
      padding: 0 10px 0 15px;
    }
  }
}

header{
  padding: 15px 20px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(255, 255, 255, .95);
  z-index: 3;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, .1);

  > ul{
    margin: -5px;
    overflow: auto;

    > li{
      position: relative;
      padding: 5px;
      float: left;
      box-sizing: border-box;

      &:nth-child(1){
        width: 75%;

        > a{
          float: left;
          font-size: 20px;
          line-height: 40px;
          font-weight: bold;

          > svg{
            display: block;
            width: 164px;
            height: 40px;
          }
        }

        > .wrap-search{
          margin: 0 0 0 174px;
          position: relative;

          > .hash{
            position: absolute;
            top: 0;
            left: 0;
            height: 40px;
            width: 40px;
            text-align: center;
            line-height: 40px;
            background: #CCC;
            color: #FFF;
            border-radius: 5px 0 0 5px;
            font-size: 14px;
            @extend %anim;

            > svg{
              display: inline-block;
              vertical-align: middle;
            }
          }

          > input{
            border: 1px solid #CCC;
            height: 40px;
            border-radius: 5px;
            padding: 0 15px 0 55px;
            background: #FFF;
            font-size: 14px;

            &.has-val{
              padding: 0 40px 0 55px;
            }

            &:hover,
            &:focus{
              border: 1px solid #999;

              ~ .hash{
                background: #999;
              }
            }
          }

          > .clear
          // ,
          // > .picker
          {
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
            height: 40px;
            font-size: 16px;
            color: #666;

            &:hover{
              color: #333;
            }

            > svg{
              width: 18px;
              height: 18px;
              display: inline-block;
              vertical-align: middle;
              margin: -5px 0 0 0;
            }
          }

          // > .picker{
          //   right: auto;
          //   left: 40px;
          // }
        }

        // .colorpicker{
        //   margin-left: 114px;
        // }
      }

      &:nth-child(2){
        width: 15%;
      }

      &:nth-child(3){
        width: 10%;
      }

      .colorpicker{
        position: absolute;
        margin: 5px 0 0 0;
      }

      > label{
        background: #fff;
        font-size: 11px;
        left: 16px;
        line-height: 11px;
        padding: 0 5px;
        position: absolute;
        top: 1px;
        z-index: 1;
      }

      > button{
        background: #666;
        color: #FFF;
        border-radius: 5px;
        height: 40px;
        padding: 0 10px;
        font-weight: bold;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover{
          background: #333;
        }

        span{
          display: block;
          font-size: 9px;
          font-weight: normal;
          margin: 0 0 1px 0;
        }

        > svg{
          display: inline-block;
          vertical-align: middle;
          margin: -3px 5px 0 0;
          width: 14px;
          height: 14px;
        }

        > .preview{
          display: inline-block;
          vertical-align: middle;
          width: 11px;
          height: 11px;
          border: 1px solid rgba(255, 255, 255, .5);
          margin: -2px 5px 0 0;
        }
      }
    }
  }
}

.pad-top{
  height: 70px;
}

footer{
  text-align: center;
  padding: 0 40px 40px 40px;
  font-size: 11px;
  line-height: 15px;

  a{
    font-weight: bold;

    &:hover{
      margin: 0 5px;
    }

    > svg{
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      margin: -3px 0 0 0;
    }
  }
}

section{
  padding: 20px;

  .empty{
    text-align: center;
    padding: 120px 0 110px 0;

    span{
      color: #666;
      display: block;
      font-size: 30px;
      line-height: 34px;
      margin: 0 0 10px 0;
    }

    strong{
      color: #666;
    }
  }

  > ul{
    pointer-events: none;
    margin: -10px;

    &:hover{
      > li{
        opacity: .25;

        &:hover{
          opacity: 1;
        }
      }
    }

    > li{
      pointer-events: auto;
      padding: 10px;
      font-size: 14px;
      line-height: 20px;
      color: #333;
      @extend %anim;

      &:hover{
        > ul{
          > li{
            width: 90%;
            margin: 0 5% 0 5%;

            &:nth-child(3){
              width: 5%;
            }
          }
        }
      }

      > ul{
        position: relative;
        margin: -5px;
        overflow: hidden;

        > li{
          width: 95%;
          padding: 5px;
          box-sizing: border-box;
          margin: 0 0 0 5%;
          @extend %anim;

          &:nth-child(1){
            position: absolute;
            margin: 0;
            left: 0;
            top: 0;
            bottom: 0;
            text-align: center;
            width: 5%;

            &:after{
              content: '';
              position: absolute;
              top: 5px;
              bottom: 5px;
              right: 5px;
              width: 1px;
              background: #EEE;
              z-index: 0;
            }

            > div{
              position: relative;
              background: #EEE;
              color: #999;
              font-size: 11px;
              line-height: 11px;
              font-weight: bold;
              padding: 5px;
              z-index: 1;
            }
          }

          &:nth-child(3){
            position: absolute;
            margin: 0;
            right: 0;
            top: 0;
            bottom: 0;
            text-align: center;
            width: 0%;

            button{
              width: auto;
              position: absolute;
              top: 5px;
              bottom: 5px;
              left: 5px;
              right: 5px;
              background: #666;
              color: #FFF;
              font-weight: bold;
              font-size: 11px;
              line-height: 11px;
              border-radius: 5px;

              &:hover{
                background: #333;
              }

              svg{
                width: 22px;
                height: 22px;
              }
            }
          }

          > ul.para{
            pointer-events: none;

            > li{
              position: relative;
              pointer-events: auto !important;
              display: inline;
              cursor: pointer;
              @extend %anim;

              *{
                pointer-events: none;
              }

              // span{
              //   pointer-events: auto;
              // }

              b{
                background: #EEE;
              }
            }

            &:hover{
              > li{
                opacity: .5;

                &:hover{
                  opacity: 1;
                }
              }
            }

            &.sen{
              > li{
                display: block;
                margin: 0 0 0 5%;
                padding: 0 0 10px 10px;

                &:before{
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 0;
                  bottom: 10px;
                  width: 1px;
                  background: #EEE;
                }

                .word-count{
                  position: absolute;
                  top: 0;
                  left: -5%;
                  width: 5%;
                  background: #EEE;
                  color: #999;
                  font-weight: bold;
                  font-size: 11px;
                  line-height: 11px;
                  padding: 5px 5px;
                  text-align: center;
                  box-sizing: border-box;
                }

                ul.word-length{
                  color: #999;
                  margin: 5px -10px 0 -10px;

                  li{
                    display: inline-block;
                    font-size: 11px;
                    line-height: 20px;
                    padding: 0 10px;
                    border-right: 1px solid #EEE;

                    &:last-child{
                      border-right: none;
                    }

                    strong{
                      color: #666;
                    }
                  }
                }

                .copas{
                  position: absolute;
                  top: 0;
                  bottom: 10px;
                  right: 0;
                  width: 0;
                  background: #666;
                  color: #FFF;
                  text-align: center;
                  border-radius: 5px;
                  @extend %anim;

                  &:hover{
                    background: #333;
                  }

                  svg{
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    margin: 0 auto;
                    width: 18px;
                    height: 18px;
                  }
                }

                &:hover{
                  padding: 0 10px 10px 10px;
                  // margin: 0 5% 0 5%;

                  .copas{
                    width: 5%;
                  }
                }
              }
            }
          }
          
          > ul.info{
            margin: 0 -10px;
            padding: 5px 0 0 0;

            > li{
              display: inline-block;
              color: #999;
              padding: 0 10px;
              font-size: 11px;
              line-height: 20px;
              text-align: left;
              border-right: 1px solid #EEE;

              &:last-child{
                border-right: none;
              }

              strong{
                color: #666;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){
  header{
    > ul{
      > li{
        &:nth-child(1){
          width: 62%;
        }

        &:nth-child(2){
          width: 22%;
        }

        &:nth-child(3){
          width: 16%;
        }
      }
    }
  }

  section{
    > ul{
      > li,
      > li:hover{
        > ul{
          > li{
            width: 87.5%;
            margin: 0 0 0 12.5%;

            &:nth-child(1){
              width: 12.5%;
              margin: 0;
            }

            &:nth-child(3){
              position: static;
              width: 87.5%;
              margin: 0 0 0 12.5%;

              button{
                position: static;
                width: 100%;
                height: 28px;
                padding: 5px;
                box-sizing: border-box;

                svg{
                  width: 18px;
                  height: 18px;
                }
              }
            }

            ul.para.sen{
              > li,
              > li:hover{
                margin: 0 0 0 15%;
                padding: 0 0 10px 10px;

                .word-count{
                  width: 15%;
                  left: -15%;
                }

                .copas{
                  position: static;
                  width: auto;
                  height: 28px;
                  padding: 5px;
                  box-sizing: border-box;
                  margin: 10px 0 0 0;

                  svg{
                    position: static;
                    transform: translate(0, 0);
                  }
                }
              }
            } 
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){
  section{
    > ul{
      > li,
      > li:hover{
        > ul{
          > li{
            width: 87.5%;
            margin: 0 0 0 12.5%;

            &:nth-child(1){
              width: 12.5%;
              margin: 0;
            }

            &:nth-child(3){
              position: static;
              width: 87.5%;
              margin: 0 0 0 12.5%;

              button{
                position: static;
                width: 100%;
                height: 28px;
                padding: 5px;
                box-sizing: border-box;

                svg{
                  width: 18px;
                  height: 18px;
                }
              }
            }

            ul.para.sen{
              > li,
              > li:hover{
                margin: 0 0 0 15%;
                padding: 0 0 10px 10px;

                .word-count{
                  width: 15%;
                  left: -15%;
                }

                .copas{
                  position: static;
                  width: auto;
                  height: 28px;
                  padding: 5px;
                  box-sizing: border-box;
                  margin: 10px 0 0 0;

                  svg{
                    position: static;
                    transform: translate(0, 0);
                  }
                }
              }
            } 
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) and (orientation: landscape){
  header{
    > ul{
      > li{
        &:nth-child(1){
          width: 68%;
        }

        &:nth-child(2),
        &:nth-child(3){
          width: 16%;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) and (orientation: portrait){
  header{
    > ul{
      > li{
        &:nth-child(1){
          width: 100%;

          > a{
            font-size: 16px;

            > svg{
              width: 113px;
            }
          }

          > .wrap-search{
            margin: 0 0 0 123px;
          }
        }

        &:nth-child(2){
          width: 50%;
        }

        &:nth-child(3){
          width: 50%;
        }
      }
    }
  }

  .pad-top{
    height: 120px;
  }

  .Toastify__toast-container{
    width: auto !important;
    left: 30px !important;
    right: 30px !important;

    .Toastify__toast{
      border-radius: 5px;
    }
  }

  footer{
    padding: 0 20px 20px 20px;
  }
}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/